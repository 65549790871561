import { createArgusEvent } from "@/lib/argus/client";

function addReferrerPageEvent() {
  if (!document.referrer) {
    return;
  }
  return createArgusEvent("page_view", {
    pageType: "referrer",
    url: document.referrer,
  });
}

function addCurrentPageEvent() {
  return createArgusEvent("page_view", {
    pageType: "page",
    url: window.location.href,
  });
}

export { addCurrentPageEvent, addReferrerPageEvent };

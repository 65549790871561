import { GrowthBook } from "@growthbook/growthbook";

import type { Attributes } from "@/lib/ab-testing/attributes";

import { addAbTestEvent } from "@/lib/ab-testing/analytics";
import * as attributes from "@/lib/ab-testing/attributes";
import { getAbTestFeature, splitUrlFeature } from "@/lib/ab-testing/features";
import { getEnv } from "@/lib/helpers/solvariEnv";
import { addSentryProject } from "@/lib/integrations/sentry/sentry";

declare global {
  interface Window {
    abTesting?: GrowthBook;
    abTestingRender?: () => void;
  }
}

// Attributes are used to target where the feature should run
function getAttributes(): Attributes {
  return {
    solvariSession: attributes.solvariSession(),
    solvariClientId: attributes.solvariClientId(),
    url: attributes.url(),
    urlFull: attributes.urlFull(),
    urlPath: attributes.urlPath(),
    urlDomain: attributes.urlDomain(),
    siteLocale: attributes.siteLocale(),
    screenWidth: attributes.screenWidth(),
    hasForm: attributes.hasForm(),
    formId: attributes.formId(),
    formGroupId: attributes.formGroupId(),
    siteType: attributes.siteType(),
    expertSiteTheme: attributes.expertSiteTheme(),
  };
}

async function initAbTesting() {
  const growthBook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: "sdk-QSCBGOnGSKSAX05C",
    enableDevMode: getEnv().config.envFE !== "production",
    backgroundSync: false,
    subscribeToChanges: false,
    attributes: getAttributes(),
    trackingCallback: addAbTestEvent, // When a feature gets triggered it will send an event to gtm and Argus
  });

  await growthBook.loadFeatures();

  // Bind it to the window so that other apps can check for features without a second instance
  window.abTesting = growthBook;

  try {
    splitUrlFeature();
  } finally {
    // Renders the page in case it was hidden by an anti flicker snippet
    if (window.abTestingRender) {
      window.abTestingRender();
    }
  }

  addSentryProject({
    name: "ab-testing",
    path: null,
    environment: "production",
    // The DSN is the same for all projects
    dsn: "https://daf496c1b5b158862d5fed761906c494@o1412996.ingest.sentry.io/4506196233027584",
    addData() {
      return {
        contexts: {
          attributes: growthBook.getAttributes() as Record<string, unknown>,
          features: growthBook.getFeatures() as Record<string, unknown>,
        },
      };
    },
  });
}

export { getAbTestFeature, getAttributes, initAbTesting };

import type { Experiment, Result } from "@growthbook/growthbook";

import { createArgusEvent, createGtmEvent } from "@/lib/argus";

function addAbTestEvent(
  experiment: Experiment<unknown>,
  result: Result<unknown>,
) {
  void createGtmEvent("experiment_impression", {
    variantId: result.key,
    experimentId: experiment.key,
  });
  void createArgusEvent("google_optimize", {
    variantId: result.key,
    experimentId: experiment.key,
  });
}

export { addAbTestEvent };

import { getMetadataFromArgusSnakeCase } from "@solvari/common-fe/argus";
import { pick, shake } from "radash";

async function initHeyflowForm() {
  const heyflowRoot = document.querySelector<HTMLElement>("heyflow-wrapper");
  const heyflowScript = document.querySelector<HTMLScriptElement>(
    "#heyflow-form-script",
  );
  if (!heyflowRoot || !heyflowScript) {
    return;
  }

  const metadata = await getMetadataFromArgusSnakeCase();

  const params = shake(
    pick(metadata, [
      "utm_campaign",
      "utm_content",
      "utm_medium",
      "utm_source",
      "utm_term",
      "landing_page",
      "landing_page_referrer",
      "current_page_referrer",
      "current_page",
    ]),
    (value) => !value,
  );

  heyflowRoot.setAttribute(
    "url-parameters",
    new URLSearchParams(params).toString(),
  );

  heyflowScript.setAttribute("src", heyflowScript.dataset.src!);
}

export { initHeyflowForm };

import type { LocaleIso } from "@/lib/helpers/locales";

import { getForm, getFormProp } from "@/lib/ab-testing/helpers";
import { getSessionUuid, getSolvariClientId } from "@/lib/argus/client";
import { getSiteLocale } from "@/lib/helpers/locales";

declare global {
  interface SiteConfig {
    themeId?: number | string;
  }
  interface Window {
    siteConfig?: SiteConfig;
  }
}

interface Attributes {
  expertSiteTheme: number | string | null;
  formGroupId: number | null;
  formId: number | null;
  hasForm: boolean;
  screenWidth: number;
  siteLocale: LocaleIso;
  siteType: "expert" | "platform" | "satellite";
  solvariClientId: string;
  solvariSession: string;
  url: string;
  urlDomain: string;
  urlFull: string;
  urlPath: string;
}

function solvariSession(): Attributes["solvariSession"] {
  return getSessionUuid()!;
}

function solvariClientId(): Attributes["solvariClientId"] {
  return getSolvariClientId()!;
}

function url(): Attributes["url"] {
  return `${window.location.origin}${window.location.pathname}`;
}

function urlFull(): Attributes["urlFull"] {
  return window.location.href;
}

function urlPath(): Attributes["urlPath"] {
  return window.location.pathname;
}

function urlDomain(): Attributes["urlDomain"] {
  return window.location.host;
}

function siteLocale(): Attributes["siteLocale"] {
  return getSiteLocale();
}

function screenWidth(): Attributes["screenWidth"] {
  return window.screen.width;
}

function hasForm(): Attributes["hasForm"] {
  return !!getForm();
}

function formId(): Attributes["formId"] {
  const form = getForm();
  if (!form) {
    return null;
  }
  return getFormProp(form, "formId") ?? null;
}

function formGroupId(): Attributes["formGroupId"] {
  const form = getForm();
  if (!form) {
    return null;
  }
  return getFormProp(form, "formGroupId") ?? null;
}

function siteType(): Attributes["siteType"] {
  if (window.siteConfig?.themeId) {
    return "expert";
  }
  if (window.location.hostname.includes(".solvari.")) {
    return "platform";
  }
  return "satellite";
}

function expertSiteTheme(): Attributes["expertSiteTheme"] {
  return window.siteConfig?.themeId ?? null;
}

export {
  expertSiteTheme,
  formGroupId,
  formId,
  hasForm,
  screenWidth,
  siteLocale,
  siteType,
  solvariClientId,
  solvariSession,
  url,
  urlDomain,
  urlFull,
  urlPath,
};

export type { Attributes };
